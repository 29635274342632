/* unplugin-vue-components disabled */"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var vue_3 = require("vue");
var vue_router_1 = require("vue-router");
exports.default = (0, vue_1.defineComponent)({
    __name: 'App',
    setup: function (__props) {
        var router = (0, vue_router_1.useRouter)();
        var width = (0, vue_3.ref)();
        (0, vue_3.onMounted)(function () {
            width.value = window.innerWidth;
            window.addEventListener("resize", handleResize);
        });
        (0, vue_3.onBeforeUnmount)(function () {
            window.removeEventListener("resize", handleResize);
        });
        var handleResize = function () {
            if (width.value < 500 &&
                window.innerWidth >= 500 &&
                router.currentRoute.value.name !== "home") {
                router.push({ name: "home" });
            }
            else if (width.value >= 500 &&
                window.innerWidth < 500 &&
                router.currentRoute.value.name !== "home") {
                router.push({ name: "home" });
            }
        };
        return function (_ctx, _cache) {
            var _component_router_view = (0, vue_2.resolveComponent)("router-view");
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", null, [
                (0, vue_2.createVNode)(_component_router_view)
            ]));
        };
    }
});
