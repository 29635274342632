"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_router_1 = require("vue-router");
var routes = [
    {
        path: "/",
        name: "home",
        component: function () {
            return window.innerWidth < 500
                ? Promise.resolve().then(function () { return __importStar(require("../views/mobile/Home.vue")); }) : Promise.resolve().then(function () { return __importStar(require("../views/pc/Home.vue")); });
        },
    },
    {
        path: "/history",
        name: "history",
        component: function () {
            return window.innerWidth < 500
                ? Promise.resolve().then(function () { return __importStar(require("../views/mobile/History.vue")); }) : Promise.resolve().then(function () { return __importStar(require("../views/pc/History.vue")); });
        },
    },
    {
        path: "/create-poem",
        name: "CreatePoem",
        component: function () {
            return window.innerWidth < 500
                ? Promise.resolve().then(function () { return __importStar(require("../views/mobile/CreatePoem.vue")); }) : Promise.resolve().then(function () { return __importStar(require("../views/pc/CreatePoem.vue")); });
        },
    },
    {
        path: "/modify-poem",
        name: "ModifyPoem",
        component: function () {
            return window.innerWidth < 500
                ? Promise.resolve().then(function () { return __importStar(require("../views/mobile/ModifyPoem.vue")); }) : Promise.resolve().then(function () { return __importStar(require("../views/pc/ModifyPoem.vue")); });
        },
    },
    {
        path: "/search-poem",
        name: "SearchPoem",
        component: function () {
            return window.innerWidth < 500
                ? Promise.resolve().then(function () { return __importStar(require("../views/mobile/SearchPoem.vue")); }) : Promise.resolve().then(function () { return __importStar(require("../views/pc/SearchPoem.vue")); });
        },
    },
    {
        path: "/poem-detail",
        name: "PoemDetail",
        component: function () {
            return window.innerWidth < 500
                ? Promise.resolve().then(function () { return __importStar(require("../views/mobile/PoemDetail.vue")); }) : Promise.resolve().then(function () { return __importStar(require("../views/pc/PoemDetail.vue")); });
        },
    },
    {
        path: '/create-poem-by-pic',
        name: 'CreateByPic',
        component: function () {
            return window.innerWidth < 500
                ? Promise.resolve().then(function () { return __importStar(require("../views/mobile/CreateByPic.vue")); }) : Promise.resolve().then(function () { return __importStar(require("../views/pc/CreateByPic.vue")); });
        },
    },
    // 关于界面系列
    {
        path: '/about',
        name: 'About',
        component: function () {
            return window.innerWidth < 500
                ? Promise.resolve().then(function () { return __importStar(require("../views/mobile/About.vue")); }) : Promise.resolve().then(function () { return __importStar(require("../views/pc/About.vue")); });
        },
    },
    //  todo 修改mobile部分
    {
        path: "/about/about_us",
        name: "AboutUs  ",
        component: function () {
            return window.innerWidth < 500
                ? Promise.resolve().then(function () { return __importStar(require("../views/mobile/about/AboutUs.vue")); }) : Promise.resolve().then(function () { return __importStar(require("../views/pc/about/AboutUs.vue")); });
        },
    },
    {
        path: "/about/feedback",
        name: "Feedback",
        component: function () {
            return window.innerWidth < 500
                ? Promise.resolve().then(function () { return __importStar(require("../views/mobile/about/Feedback.vue")); }) : Promise.resolve().then(function () { return __importStar(require("../views/pc/about/Feedback.vue")); });
        },
    },
    {
        path: "/about/update_log",
        name: "UpdateLog",
        component: function () {
            return window.innerWidth < 500
                ? Promise.resolve().then(function () { return __importStar(require("../views/mobile/about/UpdateLog.vue")); }) : Promise.resolve().then(function () { return __importStar(require("../views/pc/about/UpdateLog.vue")); });
        },
    }
];
var router = (0, vue_router_1.createRouter)({
    history: (0, vue_router_1.createWebHistory)(),
    routes: routes,
});
exports.default = router;
