export const rhymes = [
  [
    "一东",
    "二冬",
    "三江",
    "四支",
    "五微",
    "六鱼",
    "七虞",
    "八齐",
    "九佳",
    "十灰",
    "十一真",
    "十二文",
    "十三元",
    "十四寒",
    "十五删",
  ],
  [
    "一先",
    "二萧",
    "三肴",
    "四豪",
    "五歌",
    "六麻",
    "七阳",
    "八庚",
    "九青",
    "十蒸",
    "十一尤",
    "十二侵",
    "十三覃",
    "十四盐",
    "十五咸",
  ],
  [
    "一董",
    "二肿",
    "三讲",
    "四纸",
    "五尾",
    "六语",
    "七麌",
    "八荠",
    "九蟹",
    "十贿",
    "十一轸",
    "十二吻",
    "十三阮",
    "十四旱",
    "十五潸",
    "十六铣",
    "十七筱",
    "十八巧",
    "十九皓",
    "二十哿",
    "二十一马",
    "二十二养",
    "二十三梗",
    "二十四迥",
    "二十五有",
    "二十六寝",
    "二十七感",
    "二十八俭",
    "二十九豏",
  ],
  [
    "一送",
    "二宋",
    "三绛",
    "四寘",
    "五未",
    "六御",
    "七遇",
    "八霁",
    "九泰",
    "十卦",
    "十一队",
    "十二震",
    "十三问",
    "十四愿",
    "十五翰",
    "十六谏",
    "十七霰",
    "十八啸",
    "十九效",
    "二十号",
    "二十一个",
    "二十二祃",
    "二十三漾",
    "二十四敬",
    "二十五径",
    "二十六宥",
    "二十七沁",
    "二十八勘",
    "二十九艳",
    "三十陷",
  ],
];
export const descriptions = [
  "为了考研，苦心准备，然而连续两年都没考上，想来年再次准备，写一首咏志诗，表达我的强烈决心",
  "炎炎夏日本应该躺在空调房里自然醒，结果却要早起挤公交，最后挤得一身汗来上学，心情极其差",
  "写一首感情充沛的怀人诗，描写在秋天的傍晚，老人在等归家的游子，他拄着拐杖，眼中泛着泪光",
  "To avoid overheating, it’s key to stay hydrated. If your home is without water during a power outage, be sure to stock up on bottled water.",
];
